import { Observable } from "rxjs";
import { RespInterface, RespListInterface } from "@common/ports/interfaces";

import { FormPaymentInterface } from "@form-payment/ports/interfaces";

export abstract class FormPaymentAbstraction {
  abstract list(query: string): Observable<RespListInterface<FormPaymentInterface>>;
  abstract create(formPayment: FormPaymentInterface): Observable<RespInterface<FormPaymentInterface>>;
  abstract update(formPayment: FormPaymentInterface): Observable<RespInterface<string>>;
  abstract read(id: string): Observable<RespInterface<FormPaymentInterface>>;
  abstract delete(id: string): Observable<RespInterface<FormPaymentInterface>>;
}
